import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { getCookie } from '@/lib/auth-cookies-new';
import withAuth from '@/utils/withAuth';

const CspAppInit = () => {
  const router = useRouter();
  useEffect(() => {
    if (getCookie(null, `token`)) {
      router.replace(`/summary-dashboard`);
    }
  }, []);
  return null;
};

export default withAuth(CspAppInit);
